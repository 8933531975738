<template>
  <div class="vg_wrapper">
    <el-tabs
        v-model="activeName"
        type="border-card"
    >
      <el-tab-pane label="单据信息" name="first" :key="'first'">
        <childTab1 @isShow="isShow"/>
      </el-tab-pane>
      <el-tab-pane label="审批流转" name="second" :key="'second'" :lazy="true">
        <childTab2 :isShow="isSho"/>
      </el-tab-pane>
      <el-tab-pane label="相关文档" name="third" :key="'third'">
        <childTab3/>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import MsotEditMain from "@/views/StockManagement/MsotManage/TabChild/MsotEditMain";
import MsotEditAppr from "@/views/StockManagement/MsotManage/TabChild/MsotEditAppr";
import MsotEditAbout from "@/views/StockManagement/MsotManage/TabChild/MsotEditAbout";

export default {
  name: "MsotEdit",
  components: {
    childTab1: MsotEditMain,
    childTab2: MsotEditAppr,
    childTab3: MsotEditAbout
  },
  data() {
    return {
      activeName: "first",
      isSho:true,
    }
  },
  methods: {
    isShow(val){
      this.isSho = val;
    }
  }
}
</script>

<style scoped>

</style>